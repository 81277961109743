<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="邀请员工"
    width="25%"
    @close="hideDialog"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="0px"
      class="mb-s-xs"
    >
      <el-form-item prop="phone" class="w-full">
        <el-input
          v-model="ruleForm.phone"
          maxlength="11"
          type="text"
          placeholder="请输入手机号"
        >
        </el-input>
      </el-form-item>
      <el-form-item class="w-full" prop="smsCaptcha">
        <el-input
          v-model="ruleForm.smsCaptcha"
          type="text"
          maxlength="6"
          placeholder="请输入验证码"
          ><template #append>
            <msgCode :mobile="ruleForm.phone" :type="'addStaff'" />
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="hideDialog">取消</el-button>
        <el-button
          type="primary"
          class="text-gray-100 bg-primary"
          @click="submitForm()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import msgCode from "@/components/msgCode/msgCode";
import { mobileValidate } from "@/utils/validate";
import { addStaff } from "@/api/account/index";
export default {
  name: "InviteDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    msgCode,
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        phone: "",
        smsCaptcha: "",
      },
      rules: {
        mobile: [{ validator: mobileValidate, trigger: "blur" }],
        smsCaptcha: [
          { required: true, message: "请填写验证码", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    showDialog: {
      handler: function(val) {
        this.dialogVisible = val;
      },
    },
  },
  methods: {
    hideDialog() {
      this.$emit("hide", false);
    },
    submitForm() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          addStaff({
            ...this.ruleForm,
          }).then(() => {
            this.$message.success("新增成功");
            this.$emit("update", false);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
