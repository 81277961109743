import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

export function captchasSmsApi(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/captchas/sms",
    method: "post",
    data
  });
}