<template>
  <div>
    <span
      v-if="isSendMsg"
      class="w-s-xxxl block text-center text-warning cursor-pointer"
      >{{ count }}s</span
    >
    <span
      v-else
      class="w-s-xxxl block text-center text-warning cursor-pointer"
      @click="handleGetMsgCode"
      >获取验证码</span
    >
  </div>
</template>

<script>
import { captchasSmsApi } from "@/api/captchas";

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    mobile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      count: 60,
      isSendMsg: false,
      timer: null,
    };
  },
  mounted() {
    window.z = this;
  },
  methods: {
    async handleGetMsgCode() {
      if (!this.mobile || !this.mobile.length === 11) {
        this.$message.warning("请输入手机号");
        return;
      }
      await captchasSmsApi({
        type: this.type,
        phone: this.mobile,
      });
      this.isSendMsg = true;
      clearInterval(this.timer);
      this.timer = window.setInterval(() => {
        if (this.count) {
          this.count--;
        } else {
          clearInterval(this.timer);
          this.count = 60;
          this.isSendMsg = false;
        }
      }, 1000);
    },
  },
};
</script>
