<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="重置员工密码"
    width="35%"
    @close="hideDialog"
    :close-on-click-modal="false"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="0px"
      class="mb-s-xs"
    >
      <el-form-item class="w-full">
        <p>接收验证码的手机号：{{ phone }}</p>
      </el-form-item>
      <el-form-item class="w-full" prop="smsCaptcha">
        <el-input
          v-model="ruleForm.smsCaptcha"
          type="text"
          maxlength="6"
          placeholder="请输入手机收到的验证码"
          ><template #append>
            <msgCode :mobile="staffInfo.phone" :type="'resetStaffPassword'" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password" class="w-full">
        <el-input
          v-model="ruleForm.password"
          type="password"
          placeholder="请设定新登录密码(8-16位数字或字母)"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="repeatPassword" class="w-full">
        <el-input
          v-model="ruleForm.repeatPassword"
          type="password"
          placeholder="请输入密码"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="hideDialog">取消</el-button>
        <el-button
          type="primary"
          class="text-gray-100 bg-primary"
          @click="submitForm()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import msgCode from "@/components/msgCode/msgCode";
import { captchaValidate, passwordValidate } from "@/utils/validate";
import { resetStaffPassword } from "@/api/account/index";
export default {
  name: "InviteDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    staffInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    msgCode,
  },
  data() {
    const samePasswordValidater = (rule, value, callback) => {
      if (this.ruleForm.password !== this.ruleForm.repeatPassword) {
        return callback(new Error("两次输入密码不一致"));
      }
      return callback();
    };
    return {
      dialogVisible: false,
      ruleForm: {
        smsCaptcha: "",
        password: "",
        repeatPassword: "",
      },
      rules: {
        smsCaptcha: [{ validator: captchaValidate, trigger: "blur" }],
        password: [{ validator: passwordValidate, trigger: "blur" }],
        repeatPassword: [
          { validator: passwordValidate, trigger: "blur" },
          { validator: samePasswordValidater, trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    showDialog: {
      handler: function(val) {
        this.dialogVisible = val;
      },
    },
  },
  computed: {
    phone() {
      if (!this.staffInfo || !this.staffInfo.phone) return "";
      const str = this.staffInfo.phone;
      return str.slice(0, 3) + "****" + str.slice(7, 11);
    },
  },
  methods: {
    hideDialog() {
      this.$emit("hide", false);
    },
    submitForm() {
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          resetStaffPassword({
            ...this.ruleForm,
            id: this.staffInfo.id,
          }).then(() => {
            this.$message.success("修改成功");
            this.$emit("update", false);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
