<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="邀请员工"
    width="25%"
    @close="hideDialog"
  >
    <!-- <div class="mb-8">
      <p class="mb-8">分享链接</p>
      <el-input v-model="link" size="small" placeholder="">
        <template #append>复制</template>
      </el-input>
    </div> -->
    <!-- <div class="mb-8">
      <p>分享二维码</p>
      <div class="flex justify-between">
        <div>
          <div class="flex">
            <img alt="" class="" />
            <div>
              <p>成都大气经销商</p>
              <p>邀请你加入公司</p>
            </div>
          </div>
          <img class="m-auto block" alt="" />
          <p class="text-black-100 text-center">扫描加入公司</p>
          <p class="text-gray-900 text-center text-xs">
            来源：www.px.threesoft.com
          </p>
          <p class="text-gray-900 text-center text-xs">
            有备社区-东方思锐旗下免费的线上交流网站
          </p>
        </div>
        <div
          class="divide-primary grid grid-cols-2 divide-x w-1/3 h-24 text-center"
        >
          <div class="text-primary cursor-pointer">下载二维码</div>
          <div class="text-primary cursor-pointer">下载卡片</div>
        </div>
      </div>
    </div> -->
    <div>
      <p class="mb-8">分享邀请码</p>
      <el-input v-model="inviteCode" size="small" disabled placeholder="">
        <template #append class=" cursor-pointer">
          <el-button @click="copyCode">
            复制
          </el-button>
        </template>
      </el-input>
    </div>
  </el-dialog>
</template>

<script>
import { getInviteCode } from "@/api/company";
export default {
  name: "InviteDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      link: "",
      barCode: "",
      inviteCode: "",
    };
  },
  watch: {
    showDialog: {
      handler: function(val) {
        this.dialogVisible = val;
      },
    },
  },
  mounted() {
    this.getInviteCode();
  },
  methods: {
    hideDialog() {
      this.$emit("hide", false);
    },
    getInviteCode() {
      getInviteCode().then((res) => {
        this.inviteCode = res;
      });
    },
    copyCode() {
      //复制链接
      var _this = this;
      if (_this.inviteCode) {
        _this.$copyText(_this.inviteCode).then(
          function(e) {
            _this.$message({
              message: "已复制到剪贴板",
              type: "success",
            });
            console.log(e);
          },
          function(e) {
            _this.$message({
              message: "复制失败",
              type: "error",
            });
            console.log(e);
          }
        );
      } else {
        _this.$message({
          message: "邀请码不存在",
          type: "error",
        });
      }
    },
  },
};
</script>
