import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";


export function staffList(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs",
    method: "get",
    params
  });
}

export function addStaff(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs",
    method: "post",
    data
  });
}

export function getRoles(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/roles",
    method: "get",
    params
  });
}

export function setStaffRole(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs/role",
    method: "post",
    data
  });
}

export function applyStaff(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs/apply",
    method: "post",
    data
  });
}

export function agreeStaff(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs/agree/" + data.id,
    method: "post",
    data
  });
}

export function refuseStaff(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs/refuse/" + data.id,
    method: "post",
    data
  });
}

export function deleteStaff(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs",
    method: "delete",
    data
  });
}

export function resetStaffPassword(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/staffs/reset-password/" + data.id,
    method: "post",
    data
  });
}

export function queryCacheInfo(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/three-in/in/three-register-cache/detail",
    method: "get",
    params
  });
}

export function getAuthInfo(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/three-in/in/user/auth",
    method: "post",
    data
  });
}

export function plateFormReg(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/three-in/in/user/register",
    method: "post",
    data
  });
}

export function getToken() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/users/token-erp",
    method: "get",
  });
}

export function deleteParts(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.coreModule,
    url: "/goods/company-parts/delete",
    method: "post",
    data
  });
}