<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="角色设置"
    width="25%"
    @close="hideDialog"
  >
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      label-width="80px"
      class="mb-s-xs"
    >
      <el-form-item prop="phone" label="选择角色">
        <el-select v-model="ruleForm.roleId" multiple placeholder="请选择角色">
          <el-option
            v-for="item in roleOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="hideDialog">取消</el-button>
        <el-button
          type="primary"
          class="text-gray-100 bg-primary"
          @click="submitForm()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { getRoles, setStaffRole } from "@/api/account/index";
export default {
  name: "SetRoleDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    staffInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        roleId: [],
      },
      roleOptions: [],
    };
  },
  watch: {
    showDialog: {
      handler: function(val) {
        this.dialogVisible = val;
        let roleId = [];
        if (this.staffInfo.roles && this.staffInfo.roles.length) {
          roleId = this.staffInfo.roles.map((item) => {
            return item.id;
          });
        }
        this.ruleForm.roleId = roleId;
      },
    },
  },
  created() {
    this.getRoleOpts();
  },
  methods: {
    getRoleOpts() {
      getRoles().then((res) => {
        this.roleOptions = res || [];
      });
    },
    hideDialog() {
      this.$emit("hide", false);
    },
    submitForm() {
      if (this.ruleForm.roleId.length == 0) {
        this.$message.error("请选择角色");
        return;
      }
      let params = {
        staffId: this.staffInfo.id, //# 员工ID
        roleId: this.ruleForm.roleId, //# 要设置的角色列表
      };
      setStaffRole(params).then(() => {
        this.$message.success("角色设置成功");
        this.$emit("update", false);
      });
    },
  },
};
</script>
