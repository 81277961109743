<template>
  <div class="parts-container" v-loading="loading">
    <SearchForm
      isShelvesButton
      :formData="formData"
      @handleQuery="handleQuery"
      @handleReset="handleReset"
    >
    </SearchForm>
    <div>
      <!-- <el-button
        size="small"
        type="primary"
        @click="newStaffDialogVisible = true"
      >
        新增员工
      </el-button> -->
      <el-button
        size="small"
        type="primary"
        @click="inviteDialogVisible = true"
      >
        邀请员工
      </el-button>
    </div>
    <el-table
      ref="table"
      class="table-base"
      size="small"
      :data="tableData"
      stripe
      border
      style="width: 100%"
      height="400px"
      row-key="swId"
      :default-sort="{ prop: 'statusName', order: 'descending' }"
      @selection-change="selectionChange"
    >
      <el-table-column
        type="selection"
        header-align="center"
        width="43"
        :selectable="handleSelectable"
      ></el-table-column>
      <el-table-column
        label="员工姓名"
        prop="staffName"
        width="166"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div class="flex justify-around px-8">
            <span>{{ scope.row.staffName }}</span>
            <i
              v-if="account !== scope.row.account"
              class="iconfont icon-line_message1 cursor-pointer"
              @click="sendMsg(scope.row)"
            ></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="员工账号"
        prop="account"
        width="166"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="员工类型"
        prop="type"
        min-width="68"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="员工手机号"
        prop="phone"
        min-width="68"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="员工角色"
        prop="roles"
        min-width="68"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ renderRole(scope.row.roles) }}
        </template>
      </el-table-column>
      <el-table-column
        label="加入日期"
        prop="joinTime"
        min-width="88"
        sortable
        :sort-method="sortMethodJoinTime"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="状态"
        prop="statusName"
        min-width="66"
        sortable
        :sort-method="sortMethodStatus"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          {{ scope.row.statusName }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200" v-if="isManager">
        <template slot-scope="scope">
          <div class="flex justify-around px-8">
            <el-button
              v-if="scope.row.status === 'toAudit'"
              type="text"
              size="mini"
              @click="agreeStaff(scope.row)"
            >
              通过
            </el-button>
            <el-button
              v-if="scope.row.status === 'toAudit'"
              type="text"
              size="mini"
              @click="refuseStaff(scope.row)"
            >
              拒绝
            </el-button>
            <el-button
              v-if="scope.row.status === 'normal'"
              type="text"
              size="mini"
              @click="handleResetPsd(scope.row)"
            >
              重置密码
            </el-button>
            <el-button
              v-if="scope.row.status === 'normal'"
              type="text"
              size="mini"
              @click="handleSetRole(scope.row)"
            >
              设置角色
            </el-button>
            <el-button
              v-if="scope.row.status === 'normal'"
              type="text"
              size="mini"
              @click="deleteStaff(scope.row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-bottom">
      <template>
        <el-checkbox v-model="selectionAll" :disabled="!tableData.length">
          全选当前查询结果
        </el-checkbox>
        <el-divider direction="vertical" class="divider-base"></el-divider>
        <span>
          已选中
          {{ selectionAll ? pageData.total : selections.length }}
          条
        </span>
        <el-divider direction="vertical" class="divider-base"></el-divider>
        <span v-if="isManager">批量操作</span>
        <el-button
          v-if="isManager"
          class="m-l-8"
          size="mini"
          type="primary"
          :disabled="!(selectionAll || selections.length)"
          @click="patchDelete"
        >
          删除
        </el-button>
      </template>
      <el-pagination
        class="pagination-base"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="pageData.page"
        :page-sizes="pageData.pageSizes"
        :page-size="pageData.pageSize"
        :pager-count="pageData.pagerCount"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <NewStaffDialog
      :show-dialog="newStaffDialogVisible"
      @hide="hideNewStaffDialog"
      @update="afterUpdate"
    />
    <InviteDialog
      :show-dialog="inviteDialogVisible"
      @hide="hideInviteDialog"
      @update="afterUpdate"
    />
    <ResetPsdDialog
      :show-dialog="resetDialogVisible"
      :staffInfo="staffInfo"
      @hide="hideResetDialog"
      @update="afterUpdate"
    />
    <SetRoleDialog
      :show-dialog="setRoleDialogVisible"
      :staffInfo="staffInfo"
      @hide="hideSetRoleDialog"
      @update="afterUpdate"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SearchForm from "./components/searchForm.vue";
import InviteDialog from "./components/Invite.vue";
import NewStaffDialog from "./components/newStaff.vue";
import ResetPsdDialog from "./components/resetPassword.vue";
import SetRoleDialog from "./components/setRole.vue";
import { staffList, deleteStaff, agreeStaff, refuseStaff } from "@/api/account";

const defaultFormData = {
  Keyword: "",
};

export default {
  components: {
    SearchForm,
    InviteDialog,
    NewStaffDialog,
    ResetPsdDialog,
    SetRoleDialog,
  },
  data() {
    return {
      defaultFormData,
      partsEditVisible: false,
      newStaffDialogVisible: false,
      resetDialogVisible: false,
      inviteDialogVisible: false,
      setRoleDialogVisible: false,
      formData: { ...defaultFormData },
      selections: [],
      selectionAll: false,
      batchEditParam: {
        type: "",
        ids: [],
        searchCond: {},
      },
      pageData: {
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        pagerCount: 5,
      },
      loading: false,
      staffInfo: {},
      tableData: [],
    };
  },
  watch: {
    selectionAll: {
      handler: function(val) {
        console.log(val);
        const tableEl = this.$refs.table;
        if (tableEl) {
          this.tableData.forEach((item) => {
            tableEl.toggleRowSelection(item, val);
          });
        }
        if (val) {
          this.selections = this.tableData.map((item) => item.id);
        } else {
          this.selections = [];
        }
      },
    },
  },
  computed: {
    ...mapState({
      isManager: (state) => state.profile && state.profile.isManager,
      account: (state) => state.profile && state.profile.account,
      uid: (state) => state.profile && state.profile.id,
    }),
  },
  created() {
    this.getList();
  },
  methods: {
    sortMethodStatus(a, b) {
      if (a == "待审核") return 1;
      return 0;
    },
    sortMethodJoinTime(a, b) {
      if (a < b) return 1;
      return 0;
    },
    renderRole(roles) {
      let arr = [];
      if (roles && roles.length) {
        arr = roles.map((item) => {
          return item.name;
        });
      }
      return arr.join("、");
    },
    sendMsg(e) {
      console.log(e);
      console.log(this.account);
      this.$store.dispatch("setChatId", e.userId);
      this.$store.dispatch("changeIMVisible", true);
    },
    hideNewStaffDialog() {
      this.newStaffDialogVisible = false;
    },
    hideInviteDialog() {
      this.inviteDialogVisible = false;
    },
    hideResetDialog() {
      this.resetDialogVisible = false;
    },
    hideSetRoleDialog() {
      this.setRoleDialogVisible = false;
    },
    afterUpdate() {
      this.newStaffDialogVisible = false;
      this.inviteDialogVisible = false;
      this.resetDialogVisible = false;
      this.setRoleDialogVisible = false;
      this.pageData.page = 1;
      this.getList();
    },
    // 查询
    handleQuery() {
      this.selectionAll = false;
      this.pageData.page = 1;
      this.getList();
    },
    // 重置
    handleReset() {
      this.formData = { ...defaultFormData };
    },
    handleResetPsd(data) {
      this.staffInfo = data;
      this.resetDialogVisible = true;
    },
    // 显示批量编辑弹框
    showEditDialog() {
      const batchEditParam = this.batchEditParam;
      // 按查询结果
      if (this.selectionAll) {
        batchEditParam.type = "searchResult";
        batchEditParam.searchCond = JSON.parse(JSON.stringify(this.formData));
      } else if (this.selections.length) {
        batchEditParam.type = "selection";
        batchEditParam.ids = this.selections.map((item) => item.id);
      }
      this.partsEditVisible = true;
    },
    hideEditDialog() {
      this.partsEditVisible = false;
    },
    agreeStaff(row) {
      this.$confirm(`确定通过[${row.staffName}]的申请?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        agreeStaff(row).then(() => {
          this.getList();
        });
      });
    },
    refuseStaff(row) {
      this.$confirm(`确定拒绝[${row.staffName}]的申请?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        refuseStaff(row).then(() => {
          this.getList();
        });
      });
    },
    handleSetRole(row) {
      this.staffInfo = row;
      this.setRoleDialogVisible = true;
    },
    deleteStaff(row) {
      this.$confirm("你确定要删除当前员工吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //调用删除接口
        deleteStaff({
          searchAll: this.selectionAll,
          cond: { ...this.formData },
          ids: [row.id],
        }).then(() => {
          this.getList();
        });
      });
    },
    patchDelete() {
      if (!this.selections.length) {
        this.$message({
          message: "你还没有选择数据",
          type: "warning",
        });
        return false;
      }
      this.$confirm("你确定要删除当前选择的员工吗？")
        .then(() => {
          //调用删除接口
          deleteStaff({
            searchAll: this.selectionAll,
            cond: { ...this.formData },
            ids: this.selections.map((item) => item.id),
          }).then(() => {
            this.getList();
          });
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    getList() {
      this.loading = true;
      // 清除展开状态
      const params = {
        ...this.formData,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize,
      };
      staffList(params)
        .then((res) => {
          this.loading = false;
          this.pickList(res.rows || []);
          this.pageData.total = res.totalSize || 0;
        })
        .catch(() => {
          this.loading = false;
          this.pageData.page = 1;
          this.pageData.total = 0;
          this.tableData = [];
        });
    },
    pickList(list) {
      this.tableData = list.map((item) => {
        return {
          ...item,
          loading: false,
          info: {},
        };
      });
    },
    // 复选框事件
    selectionChange(val) {
      this.selections = val;
    },
    handleSizeChange(val) {
      this.pageData.page = 1;
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getList();
    },
    // 没有id时无法勾选
    handleSelectable(row) {
      return !!row.id;
    },
    toggleRowExpansion() {
      const tableEl = this.$refs.table;
      if (tableEl) {
        this.tableData.forEach((item) => {
          tableEl.toggleRowExpansion(item, false);
        });
      }
    },
    // 编辑
    handleEdit(row) {
      let url = `/goodsManage/goodsEdit?id=${row.id}&type=${this.formData.type}`;
      sessionStorage.setItem("oldPath", url);
      this.$router.push({
        path: "/goodsManage/goodsEdit",
        query: {
          id: row.id,
          type: this.formData.type,
        },
      });
    },
    handleCopy(row) {
      if (this.shareInfo.link) {
        const text = `${this.shareInfo.link}/skuDetail?id=${row.id}&type=${this.formData.type}`;
        this.$copyText(text).then(
          () => {
            this.$message.success("已复制到剪贴板");
          },
          () => {
            this.$message.error("复制失败");
          }
        );
      } else {
        this.$message.error("链接不存在");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.parts-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .table-base {
    margin-top: @margin-size-main;
    .el-table__expanded-cell {
      padding: 0;
    }
    td .cell {
      padding-top: 1px;
      padding-bottom: 1px;
    }
  }
  .table-bottom {
    display: flex;
    align-items: center;
    margin-top: @margin-size-secondary;
    height: 32px;
    position: relative;
    .pagination-base {
      align-self: flex-end;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
</style>
