import request, {
  SERVICE_MODULE_MAP
} from "@/utils/request";

export function getCompany() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies",
    method: "get",
  });
}

export function addcompany(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies",
    method: "post",
    data
  });
}

export function updateCompany(data) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/update",
    method: "post",
    data
  });
}

export function getInviteCode() {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/staff-invite-code",
    method: "get",
  });
}

export function getInviteInfo(params) {
  return request({
    baseURL: SERVICE_MODULE_MAP.supportModule,
    url: "/account/companies/by-staff-invite-code",
    method: "get",
    params
  });
}